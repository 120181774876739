import { ElMessage, ElMessageBox } from 'element-plus';
import myAxiosWxPay from '../axios/index_v2';

import { wsHost } from '../../configs/config.json';

export default class QueryQrCodeClass {
  constructor(setFilterOfContainer, maskDivState, storeDatas) {
    this.setFilterOfContainer = setFilterOfContainer; // 登陆信息数据
    this.maskDivState = maskDivState; // 遮罩层展示数据
    this.storeDatas = storeDatas;// 数据

    this.ws = null; // websocket
    this.timer = null; // setInterVal定时器
    this.timeOuter = null; // timeout定时器
    this.wsConnected = false; // 标识webSocket连接状态[true:连接成功，false：未成功]
    // this.loader = null; // 加载提示
    // this.currentOpState = false; // 是否关闭窗体[true:关闭，false：取消关闭]
    this.overTime = 3 * 60 * 1000; // 过期时间（纳秒）3分钟
    this.perHartTime = 5000; // 心跳检测时间（秒）
  }

  // 点击支付按钮（其他地方有调用）
  payFuncByQC = (options) => {
    const that = this;
    Object.assign(this.maskDivState, {
      options,
      maskCloseWsFun: (b) => {
        // 设置dialogQrCodeClass.closeDivFunc函数使用
        if (that.ws !== null) {
          // websocket保持联接状态
          // that.currentOpState = true;
          that.sendMessage(b);
        } else {
          Object.assign(this.maskDivState, {
            payStatus: false,
            options: {},
            closeWsFun: null,
          });
        }
      },
      newQrCodeFunc: () => {
        this.axiosPayFunc(options.id, this.setFilterOfContainer.userNickname, `支付合同号${options.contactNum}`);
      },
    });
    this.axiosPayFunc(
      options.id,
      this.setFilterOfContainer.userNickname,
      `支付合同号${options.contactNum}`, // 合同号信息
    );
  };

  /**
   * 向后台请求支付数据
   * @param {string} uuid  产品ID
   * @param {string} currentUser  当前用户
   * @param {string} descContent 支付描述
   */
  axiosPayFunc = (uuid, currentUser, descContent) => {
    myAxiosWxPay()
      .initAxios({
        method: 'POST',
        url: '/wxpay',
        Headers: { Accept: 'application/json; charset=utf-8' },
        data: {
          uuid,
          currentUser,
          descContent,
        },
      })
      .then((res) => {
        if (res.code === 200 && res.message === '获取成功') {
          this.maskDivState.payStatus = true; // 展示二维码弹出框
          this.maskDivState.payment = res.payment; // 支付金额
          this.maskDivState.qrCodeURL = JSON.parse(window.atob(res.result)).code_url;
          this.maskDivState.createTime = res.createTime;
          this.connectWs(res.result); // 创建webSocket
        } else if (res.code === 1009 || res.message === '请登陆') {
          this.setFilterOfContainer.showLoginDialog();// 展示登陆框
        } else {
          ElMessage.error('操作错误', res.message);
        }
      })
      .catch((err) => {
        ElMessageBox.alert(`${err.code}:${err.message}`, {
          title: '错误',
          confirmButtonText: '确定',
        }).then((res) => {
          if (res === 'confirm') {
            // 关闭ws
            this.closeWsFunc();
            // 关闭二维码展示
            Object.assign(this.maskDivState, {
              payStatus: false,
              options: {},
              closeWsFun: null,
            });
          }
        });
      });
  };

  // 关闭websocket
  closeWsFunc = () => {
    // 清除心跳检测
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    // 清除倒计时
    if (this.timeOuter !== null) {
      clearTimeout(this.timeOuter);
      this.timeOuter = null;
    }

    // 关闭ws
    if (this.ws !== null) {
      if (this.wsConnected) this.ws.close(); // 关闭webSocket
      this.wsConnected = false; // 标记关闭webSocket
      this.ws = null;

      // 是否关闭窗体[true:关闭，false：不关闭],关闭窗体及清空数据
      Object.assign(this.maskDivState, {
        payStatus: false, // 关闭二维码展示
        options: {},
        closeWsFun: null,
      });
    }
  };

  // WebSocket 发送信息
  sendMessage = (msg) => {
    if (this.wsConnected) {
      const msgStrify = JSON.stringify({ uuid: this.maskDivState.options.id, message: msg });
      this.ws.send(msgStrify);
    }
  };

  /**
   *  关闭支付窗口
   * @param {*} bl [true:未支付无须关闭窗体（用于过期未支付）;false:隐藏"刷新",用记手动关闭窗体]
   */
  closeMaskOfPayWindow = (bl = true) => {
    // 清除心跳检测
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    // 清除倒计时
    if (this.timeOuter !== null) {
      clearTimeout(this.timeOuter);
      this.timeOuter = null;
    }
    this.maskDivState.refQRCode = bl; // 隐藏"刷新" [true:取消关闭窗体，false:关闭窗体]
  };

  // 创建连接WebSocket
  connectWs = () => {
    //  const that = this;
    if (!window.WebSocket) {
      throw new Error('本浏览器不支持WebSocket服务,支付失败');
    }
    // 创建webSocket
    this.ws = new WebSocket(
      `${wsHost}/v3/wxpayed?uuid=${this.maskDivState.options.id}&&mb=${this.setFilterOfContainer.userNickname}`,
    );

    // 建立成功webSocket
    this.ws.onopen = () => {
      this.wsConnected = true;
      this.maskDivState.refQRCode = false;
    };

    this.ws.onerror = () => {
      this.ws.close();
      this.closeWsFunc();
    };

    // 关闭webSocket
    this.ws.onclose = () => {
      this.closeWsFunc();
    };

    // 接收信息
    this.ws.onmessage = (e) => {
      // 向服务器发送数据字段：CLOSE（关闭）,PING（心跳检测）,ERROR（超时,其他错误）
      // 接收服务器字段意思 ：SUCCESS（完成支付）， ERROR(操作错误),CLOSED(后台已关闭ws) PONG（心跳检测）
      // 思路查看：微信支付流程图-1.drawio

      switch (e.data) {
        case 'SUCCESS':
          // 清除心跳检测
          if (this.timer !== null) {
            clearInterval(this.timer);
            this.timer = null;
          }
          // 清除倒计时
          if (this.timeOuter !== null) {
            clearTimeout(this.timeOuter);
            this.timeOuter = null;
          }

          // 支付完成（发送CLOSE指令让后台关闭ws)
          this.sendMessage('CLOSE'); // 通知后台关闭ws
          if (this.maskDivState.updateDataFunc !== null) this.maskDivState.updateDataFunc(this.maskDivState.options.id);// 更新数据
          ElMessageBox.alert('支付成功！！', {
            title: '支付成功',
            confirmButtonText: '确定',
          });
          break;
        case 'CLOSED':
          // 表示服务器已关闭了所有ws连接，前端可以直接结束断开ws
          // console.log('准备关闭前端ws');
          this.closeWsFunc();//  关闭ws
          this.closeMaskOfPayWindow(false); // 关闭二维码窗体
          break;
        case 'PONG':
          // 心跳检测，同时表示未支付
          // console.log('心跳检测PONG值');
          break;
        case 'ERROR':
          // console.log('后端数据出错');
          // 服务器操作错误（发送CLOSEBEFORECHECK指令让后台关闭ws，并检测是否支付成功)
          // 向服务器发送关闭信号
          this.sendMessage('CLOSEBEFORECHECK');
          ElMessageBox.alert('出错了，请重新支付！', {
            title: '错误',
            confirmButtonText: '确定',
          });
          break;
        default:
          //  例如'PONG'，未支付、心跳检测
          break;
      }
    };

    // 超时处理
    this.timeOuter = setTimeout(() => {
      // 超时未支付， 通知后台关闭wx。展示"刷新"
      clearInterval(this.timer);
      this.sendMessage('CLOSEBEFclerrORECHECK');// 关闭服务器前向微信服务器查询支付结果
      ElMessageBox.alert('支付超时，请重新支付！', {
        title: '支付超时',
        confirmButtonText: '确定',
      });
    }, this.overTime);

    // 心跳检测
    this.timer = setInterval(() => {
      this.sendMessage('PING');
    }, this.perHartTime);
  };
}
