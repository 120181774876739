<template>
  <teleport to="body" v-if="maskDivState.payStatus">
    <div class="maskDiv" @click="dialogQrCodeClass.closeDivFunc">
      <div class="descOfQrcode" @click.stop>
        <div class="titleLogo"><h1>微信支付标签</h1></div>
        <div class="titleDesc">
          <h2>欢迎使用<b>微信支付</b></h2>
        </div>
        <div class="payQrCode">
          <QrCodeVue
            background="#07c160"
            foreground="white"
            :value="maskDivStateFather.qrCodeURL"
            level="H"
            :size="qrCodeSize"
            class="qrcodeCalss"
          />
        </div>
        <div class="refresh">
          <span class="refContent" v-if="maskDivStateFather.refQRCode" @click="dialogQrCodeClass.refreshFunc"
            >刷新</span
          >
        </div>
        <div class="infomation">
          <div class="infomain">
            <div>
              <span class="title">金额 :</span>
              <span class="content amount">￥{{ maskDivStateFather.payment }}</span> 元
            </div>
            <div>
              <span class="title">合同号:</span><span class="content">{{ maskDivState.options.contactNum }}</span>
            </div>
            <div>
              <span class="title">创建时间:</span
              ><span class="content">{{ dialogQrCodeClass.formatTime(maskDivStateFather.createTime) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  // inject,
  reactive,
  ref,
} from 'vue';
import QrCodeVue from 'qrcode.vue';
import DialogQrCodeClass from './class/dialogQrCodeClass';

export default {
  name: 'paycode',
  components: { QrCodeVue },
  props: ['maskDivStateFather'],
  setup(props) {
    const qrCodeSize = ref(150);
    const maskDivState = reactive(props.maskDivStateFather);

    const dialogQrCodeClass = new DialogQrCodeClass(maskDivState);
    return {
      dialogQrCodeClass,
      maskDivState,
      qrCodeSize,
    };
  },
};
</script>
<style lang="scss" scoped>
  .maskDiv {
    background: rgba($color: #000000, $alpha: 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    min-width: 960px !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .descOfQrcode {
      width: 280px;
      height: 420px;
      background: #00c250;
      border: 1px solid white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .titleLogo {
        width: 100%;
        height: 60px;
        color: rgba($color: white, $alpha: 0);
        background: white url('../../assets/imgs/wxpay.svg') no-repeat center;
      }
      .contractNum {
        color: white;
        font-size: 12px;
        text-align: center;
      }

      .titleDesc {
        font-size: 18px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding: 15px 0 5px 0;
        box-sizing: border-box;
        text-align: center;
        b {
          font-weight: 500;
          font-size: 20px;
        }
      }
      .payQrCode {
        display: flex;
        flex-direction: row;
        justify-content: center;
        // margin-bottom: 20px;
        .qrcodeCalss {
          // border: 1px solid #05aa55;
        }
      }
      .refresh {
        height: 26px;
        display: block;
        text-align: center;
        margin: 10px 0 20px;
        color: white;
        .refContent {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .refContent::before {
          content: '';
          width: 26px;
          height: 26px;
          background: url('../../assets/imgs/refresh.svg') no-repeat center;
          background-size: 28px;
        }
      }

      .infomation {
        background: white;
        flex: 1;
        padding: 0px 10px;
        font-size: 13px;
        border: 1px solid #e8e7e7;
        box-sizing: border-box;
        font-weight: 400;
        display: flex;
        align-items: center;
        .infomain {
          div {
            display: flex;
            .title {
              width: 70px;
              text-align: justify;
              display: inline-block;
            }
            .content {
              display: block;
            }
            .amount {
              font-family: '微软雅黑';
              color: red;
              font-weight: 600;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
</style>
