import { ElMessageBox } from 'element-plus';

export default class DialogQrCodeClass {
  constructor(maskDivState) {
    this.maskDivState = maskDivState;// 遮罩层数据
  }

  // 关闭扫码遮罩层
  closeDivFunc = () => {
    // 提示框
    ElMessageBox.confirm('确定要退出扫码支付！', {
      type: 'warning',
      title: '警告',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false, // 点击蒙层取消
      closeOnPressEscape: false, // 禁止按ESC
    }).then((res) => {
      // 确定
      if (res === 'confirm') {
        if (this.maskDivState.maskCloseWsFun !== null) {
          // 退出前通知服务器，即将关闭，做好检测后关闭ws
          this.maskDivState.maskCloseWsFun('CLOSEBEFORECHECK');
        }
      }
    }).catch(() => {
      // 取消
    });
  }

  /**
   * 生成时间格式为：2023-01-01 13:14:15
   * @param {int64} timeStamp 时间戳
   */
  formatTime = (timeStamp) => {
    const strTime = new Date(timeStamp);
    const yy = strTime.getFullYear();
    const mm = strTime.getMonth() + 1;
    const dd = strTime.getDate();
    const h = strTime.getHours();
    const m = strTime.getMinutes();
    const s = strTime.getSeconds();
    return `${yy}-${mm}-${dd} ${h}:${m}:${s}`;
  }

  // 重新刷新定单获取二维码
  refreshFunc = () => {
    this.maskDivState.newQrCodeFunc();
  }
}
